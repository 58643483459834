.SearchLayout {
  position: absolute;
  top: 5.5em;
  right: 0px;
  padding-left: 1em;
  padding-right: 1em;
}

/* clears the 'X' from Internet Explorer */
.SearchLayout input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
.SearchLayout input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
.SearchLayout input[type="search"]::-webkit-search-decoration,
.SearchLayout input[type="search"]::-webkit-search-cancel-button,
.SearchLayout input[type="search"]::-webkit-search-results-button,
.SearchLayout input[type="search"]::-webkit-search-results-decoration { display: none; }


.SearchLayout > .Content {
  position: relative;
}

.SearchLayout > .Content > .Bar {
  position: absolute;
  right: 0px;
  width: 8em;
  height: 3em;
  border-radius: 1.5em;
  text-align: center;
  color: #333;
  background-color: rgba(255,255,255,0.75);
  box-shadow: 0 3px 15px 2px rgba(0,0,0,0.20);
  box-sizing: border-box;
  padding-left: 1em;
  padding-right: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas: "Icon";
  transition: width 1s;
  -webkit-transition: width 0.25s;
}

.SearchLayout > .Content > .Bar > .Term {
  grid-area: Term;
  line-height: 2em;
  width:100%;
  border: none;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  border: none;
  border-width: 0;
  box-shadow: none;
  outline: none;
  font-size: 1em;
  display: none;
}

.SearchLayout > .Content > .Bar > .Icon {
  grid-area: Icon;
  font-size: 1em;
  line-height: 3em;
  color: #035788;
  opacity: 1;
  white-space: nowrap;
  text-shadow: 0px 0px 4px #FFFFFF;
}

.SearchLayout > .Content > .Bar > .Icon:hover {
  cursor: pointer;
}

.SearchLayout > .Content > .Bar > .Control {
  grid-area: Control;
  font-size: 1em;
  line-height: 3em;
  color: silver;
  display: none;
}

.SearchLayout > .Content > .Bar > .Control:hover {
  cursor: pointer;
}

.SearchLayout[data-expanded="true"] > .Content > .Bar {
  width: 100%;
  width: 20em;
  grid-template-columns: 1fr 1em;
  grid-gap: 1.25em;
  grid-template-areas: "Term Control";
  background-color: white;
}

.SearchLayout[data-expanded="true"] > .Content > .Bar > .Term {
  display: block;
}

.SearchLayout[data-expanded="true"] > .Content > .Bar > .Control {
  display: block;
}

.SearchLayout[data-expanded="true"] > .Content > .Bar > .Icon {
  display: none;
  opacity: 0;
}

.SearchLayout > .Content > .Results {
  position: relative;
  top: 4em;
  right: 0em;
  box-sizing: border-box;
  padding: 1em;
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 5px;
  line-height: 1.75em;
  color: #035788;
  max-height: 14em;
  overflow-y: scroll;
  display: none;
  box-shadow: 0 3px 15px 2px rgba(0,0,0,0.20);
}

/* .SearchLayout > .Content > .Results::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.SearchLayout > .Content > .Results::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.35);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
} */

.SearchLayout > .Content > .Results[data-active="true"] {
  display: block;
}

.SearchLayout > .Content > .Results .Question {
  display: grid;
  grid-template-columns: 1em 1fr;
  grid-gap: 0.5em;
  grid-template-areas:
    "Index Title"
    ".     Answer";
}

.SearchLayout > .Content > .Results .Question:hover {
  cursor: pointer;
}

.SearchLayout > .Content > .Results .Question > .Index {
  grid-area: Index;
}

.SearchLayout > .Content > .Results .Question > .Title {
  grid-area: Title;
  text-decoration: underline;
}

.SearchLayout > .Content > .Results .Question > .Answer {
  grid-area: Answer;
  color: rgba(0, 0, 0, 0.65);
}

.SearchLayout > .Content > .Results .Question b {
  /* color: #333; */
}

@media (min-width: 45.5em) {
 .SearchLayout {
   padding-left: 2em;
   padding-right: 2em;
 }
}
