.RectButton {
  font-size: 1em;
  position: relative;
  height: 3em;
  min-width: 8em;
  border: 1px solid #EEE;
  border-radius: 5px;
  text-align: center;
  line-height: 2em;
  max-width: 15em;
  width: 100%;
  color: silver;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.25s;
}

.RectButton:hover {
  cursor: pointer;
  opacity: 1;
}

.RectButton:active {
  background-color: #faaf17;
  color: black;
}

.RectButton[data-theme="red"] {
  border-color: #b02b13;
  color: #fff;
  background: linear-gradient(180deg,#e6391a,#d53417);
  box-shadow: inset 0 1px 0 0 #e73d1f, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
}

.RectButton[data-theme="blue"] {
  border-color: #3f4eae;
  color: #fff;
  background: linear-gradient(180deg,#6371c7,#5563c1);
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
}

.RectButton[data-theme="darkblue"] {
  border-color: #035788;
  color: #fff;
  background: linear-gradient(180deg,#035788,#035788);
  box-shadow: inset 0 1px 0 0 #035788, 0 1px 0 0 #03578817, 0 0 0 0 transparent;
}

.RectButton[data-theme="sky"] {
  border-color: #32a0fa;
  background-color: #32a0fa;
  background-image: linear-gradient(#32a0fa, #277ec8);
}

.RectButton[data-theme="green"] {
  border-color: #2f9040;
  color: #fff;
  background: linear-gradient(180deg,#449852,#2f9040);
  box-shadow: inset 0 1px 0 0 #3a9c3d, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
}

.RectButton[data-theme="cyan"] {
  border-color: #4dacc5;
  color: #fff;
  background: linear-gradient(180deg,#4fb7d4,#49b0cc);
  box-shadow: inset 0 1px 0 0 #57bcd8, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
}

.RectButton[data-theme="orange"] {
  border-color: #d69615;
  color: #fff;
  background: linear-gradient(180deg,#eca514,#dc9b16);
  box-shadow: inset 0 1px 0 0 #da9a18, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
}

.RectButton[data-theme="softred"] {
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, .09) 0%, rgba(255, 89, 89, .10) 97%);
  border: 1px solid rgba(222, 154, 82, .50);
  color: #e21515;
}

.RectButton[data-theme="softpurlple"] {
  color: #7225a9;
  background-color: white;
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, .18) 0%, rgba(111, 7, 255, .08) 100%);
  border: 1px solid #CBAFF8;
}

.RectButton[data-theme="black"] {
  border-color: black;
  color: white;
  background: black;
}

.RectButton[data-theme="bw"] {
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, .09) 0%, rgba(0, 0, 0, 0.1) 97%);
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #333;
}

.RectButton[data-theme="bare"] {
    background-color: transparent;
    border: 1px solid transparent;
    color: #035788;
}

.RectButton[data-layout="plain"]{
    background-image: none;
    border: 1px solid transparent;
    box-shadow: none;
    min-width: 0em;
}

.RectButton[data-layout="plain"][data-theme="blue"] {
  color: #3f4eae
}

.RectButton[data-working="true"] {
  background-image: none;
  background-color: DarkViolet;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: none;
  /* color: violet; */
  color: wheat;
  opacity: 1;
  pointer-events: none;
  /* background: repeating-linear-gradient(45deg, rgba(153,50,204,1), rgba(153,50,204,1) 12px, rgba(138,43,226,0.9) 18px, rgba(138,43,226,0.9) 30px); */
  background: repeating-linear-gradient(30deg, rgba(153,50,204,1), rgba(153,50,204,1) 12px, rgba(138,43,226,0.9) 18px, rgba(138,43,226,0.9) 30px);
  animation: WorkingButton  0.5s linear infinite;
}

.RectButton[data-lock="true"]{
  border: 1px dashed silver;
  background-image: none;
  pointer-events: none;
  background-color: white;
  box-shadow: none;
  color: silver;
}

@keyframes WorkingButton {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -60px 0px;
  }
}
