.Popups {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  display: none;
  background-color: rgba(0,0,0,0.35);
  text-align: center;
}

.Popups[data-populated="true"] {
  display: block;
}
