.DropControl {
  font-size: 1em;
  position: relative;
  height: 3.5em;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 5px;
}

.DropControl:hover {
  cursor: pointer;
}

.DropControl[data-layout="field"] {
  min-width: 6em;
}

.DropControl > .Label {
  position: absolute;
  top: 1.7em;
  left: 1em;
  text-align: left;
  pointer-events: none;
  font-size: 1.25em;
  transform: translateY(-0.8em);
  opacity: 0.5;
  line-height: 1em;
  transition: all 0.1s;
}

.DropControl > .Value {
  position: relative;
  width: calc(100% - 1em);
  top: 50%;
  transform: translateY(calc(-50% + 0.2em));
  background-color: transparent;
  padding: 0px;
  padding-left: 1em;
  font-size: 1.25em;
  color: #555;
  transition: all 0.1s;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.DropControl[data-focused="true"]  {
  border-color: #206893;
}

/* .DropControl[data-focused="true"]  > .Label, */
.DropControl[data-filled="true"] > .Label {
  top: 1.2em;
  left: 1.6em;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}


.DropControl > .State {
  position: absolute;
  display: inline-block;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.1s;
  opacity:0.5;
}
.DropControl[data-focused="true"] > .State {
  transform: translateY(-50%) rotate(-90deg);
}

.DropControl > .Options {
  display: none;
  background-color: white;
  position: absolute;
  max-height: 15em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 6px -6px black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f5f5f5;
  color: #777;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.DropControl[data-focused="true"]  > .Options {
  width: 100%;
  display: block;
  z-index: 10;
}

.DropControl .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
}

.DropControl .Options > .Option:hover {
  background-color: #eee;
  color: #333;
}

.DropControl .Options > .Option > .Entry {
  text-align: left;
  box-sizing: border-box;
}

.DropControl .Options > .Option > .Entry > .Subnote {
  padding-top: 0.5em;
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.7;
}

.DropControl[data-error="true"] {
  border-color: red;
  color: red;
}

.DropControl[data-error="true"] > .Label {
  animation: TextArea-error-shake  infinite 2.5s;
}

@keyframes DropControl-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
