.PageHeaderOutline {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
  grid-template-areas:
    "top"
    "middle";
}

.PageHeaderOutline > .HeaderTop {
  grid-area: top;
  width: 100%;
  text-align: left;
  /* opacity: 0.5; */
  color: #035788;
}

/* .PageHeaderOutline > .HeaderTop > .PlainButton  {
  color: #3a84a2;
  opacity: 1;
} */

.PageHeaderOutline > .HeaderMiddle {
  display: grid;
  grid-area: middle;
  grid-template-columns: 3fr 1em;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  align-items: center;
}

.PageHeaderOutline > .HeaderBottom {
  display: none;
}

.PageHeaderOutline > .HeaderMiddle > .Title {
  color: #333;
  font-size: 2em;
  font-weight: bold;
  text-align: left;
}

@media (min-width: 30.5em) {
  .PageHeaderOutline {
    padding:0px;
  }
}
