@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');
@import url('https://fonts.googleapis.com/css?family=Roboto:100');

body {
  font-family: 'Roboto';
  /*background: rgba(0,0,0,0.80);*/
  /*background: #333;*/
  /* background: #EEE; */
  color: silver;
  /*font-size: 1.25em;*/
  font-size: 1em;
}

a {
  /* text-decoration: none; */
  color: inherit;
  transition: color 0.2s;
}

a:hover {
  color: #0070c9;
}

p, li {
  line-height: 1.5em;
}

.App {
  text-align: center;
}

.TopSpace {
  display: inline-block;
  width: 100%;
  height: 3em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Frame {
  position: relative;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.hled {
  position: relative;
  top: 0.1em;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: black;
}

.hled::after {
  content: "";
  position: absolute;
  display: block;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.hled-homing::after { animation-name: halo-homing; }
.hled-sanitizing::after { animation-name: halo-sanitizing; }
.hled-rejecting::after { animation-name: halo-homing; animation-duration: 1s; }
.hled-aborting::after { animation-name: halo-aborting; }
.hled-home-error::after { animation-name: halo-home-error; }
.hled-heat-error::after { animation-name: halo-heat-error; }


/* @keyframes halo-homing {
  0%   {background-color: transparent;}
  10%  {background-color: orange;}
  20%  {background-color: transparent;}
  30%  {background-color: orange;}
  40%  {background-color: transparent;}
  50%  {background-color: orange;}
  60%  {background-color: transparent;}
  70%  {background-color: orange;}
  80%  {background-color: transparent;}
  90%  {background-color: orange;}
  100% {background-color: transparent;}
} */

@keyframes halo-homing {
  0%   {background-color: transparent;}
  10%  {background-color: orange;}
  20%  {background-color: transparent;}
  30%  {background-color: orange;}
  40%  {background-color: transparent;}
  50%  {background-color: orange;}
  60%  {background-color: transparent;}
  70%  {background-color: orange;}
  80%  {background-color: transparent;}
  90%  {background-color: orange;}
  100% {background-color: transparent;}
}


/* @keyframes halo-sanitizing {
  0%   {background-color: transparent;}
  10%  {background-color: yellow;}
  20%  {background-color: transparent;}
  30%  {background-color: yellow;}
  40%  {background-color: transparent;}
  50%  {background-color: yellow;}
  60%  {background-color: transparent;}
  70%  {background-color: yellow;}
  80%  {background-color: transparent;}
  90%  {background-color: yellow;}
  100% {background-color: transparent;}
} */

@keyframes halo-sanitizing {
  0%   {background-color: transparent;}
  25%  {background-color: yellow;}
  50%  {background-color: transparent;}
  75%  {background-color: yellow;}
  100% {background-color: transparent;}
}

@keyframes halo-aborting {
  0%   {background-color: transparent;}
  10%  {background-color: red;}
  20%  {background-color: transparent;}
  30%  {background-color: red;}
  40%  {background-color: transparent;}
  50%  {background-color: red;}
  60%  {background-color: transparent;}
  70%  {background-color: red;}
  80%  {background-color: transparent;}
  90%  {background-color: red;}
  100% {background-color: transparent;}
}

@keyframes halo-home-error {
  0%   {background-color: black;}
  9%   {background-color: black;}
  10%  {background-color: yellow;}
  19%  {background-color: yellow;}
  20%  {background-color: black;}
  29%  {background-color: black;}
  30%  {background-color: yellow;}
  39%  {background-color: yellow;}
  40%  {background-color: black;}
  49%  {background-color: black;}
  50%  {background-color: yellow;}
  59%  {background-color: yellow;}
  60%  {background-color: black;}
  69%  {background-color: black;}
  70%  {background-color: yellow;}
  79%  {background-color: yellow;}
  80%  {background-color: black;}
  100% {background-color: black;}
}


@keyframes halo-heat-error {
  0%   {background-color: black;}
  9%   {background-color: black;}
  10%  {background-color: orange;}
  19%  {background-color: orange;}
  20%  {background-color: black;}
  29%  {background-color: black;}
  30%  {background-color: yellow;}
  39%  {background-color: yellow;}
  40%  {background-color: black;}
  49%  {background-color: black;}
  50%  {background-color: yellow;}
  59%  {background-color: yellow;}
  60%  {background-color: black;}
  69%  {background-color: black;}
  70%  {background-color: red;}
  79%  {background-color: red;}
  80%  {background-color: black;}
  100% {background-color: black;}
}
