.SalesForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Name"
    "Email"
    "Company"
    "Website"
    "Desc"
    "Comment"
    "Submit"
    "Shout";
}

.SalesForm > .Name {
  grid-area: Name;
}

.SalesForm > .Email {
  grid-area: Email;
}

.SalesForm > .Company {
  grid-area: Company;
}

.SalesForm > .Website {
  grid-area: Website;
}

.SalesForm > .Desc {
  grid-area: Desc;
}

.SalesForm > .Comment {
  grid-area: Comment;
}

.SalesForm > .Submit {
  grid-area: Submit;
}

 @media (min-width: 45.5em) {
  .SalesForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Name    Name"
      "Email   Email"
      "Company Company"
      "Website Website"
      "Desc    Desc"
      "Comment Comment"
      "Comment Comment"
      "Submit  Submit"
      "Shout   Shout";
    }
}
