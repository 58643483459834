.NotFoundPage {
  /*background-color: red;  */
  text-align: left;
}

.NotFoundPage > .Filter > .Input {
  width: 100%;
}

.NotFoundPage > .Results {
  margin-top: 1.5em;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.75em;
}

.NotFoundPage > .Results .Question {
  color: #035788;
  display: grid;
  grid-template-columns: 1em 1fr;
  grid-gap: 0.5em;
  padding-bottom: 0.5em;
  grid-template-areas:
    "Index Title"
    ".     Answer";
}

.NotFoundPage > .Results .Question:hover {
  cursor: pointer;
}

.NotFoundPage > .Results .Question > .Index {
  grid-area: Index;
}

.NotFoundPage > .Results .Question > .Title {
  grid-area: Title;
  text-decoration: underline;
}

.NotFoundPage > .Results .Question > .Answer {
  grid-area: Answer;
  color: rgba(0, 0, 0, 0.65);
}

.NotFoundPage > .Results .Question b {
  /* color: #333; */
  font-size: 1.2em;
}
