.Separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,0.1);
  margin-top: 1em;
  margin-bottom: 1em;
}

.Separator[data-theme="white"] {
  background-color: white;
}

.Separator[data-display="full"] {
  position: relative;
  width: calc(100% + 2em);
  left:-1em;
  height: 2px;
}

.Separator[data-collapsed="true"] {
  margin-top: 0px;
  margin-bottom: 0px;
}
