.Form {
  width: 100%;
  display: grid;
}

.Form a {
  color: #035788;
}

.Form  .Input,
.Form  .DropControl,
.Form  .Readout {
  margin: 0px;
}

.Form > .Shout {
  grid-area: Shout;
  color: gray;
  margin: 0px; 
}

.Form > .Shout[data-error="true"] {
  color: red;
}

.Form > .Icon {
  font-size: 5em;
  color: limegreen;
}
