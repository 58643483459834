.MenuItemLayout {
  font-size: 1em;
  position: relative;
  width: 100%;
  border-radius: 5px;
  color: silver;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
}

.MenuItemLayout > .Nested > .MenuItemLayout {
  /* border: none; */
}

.MenuItemLayout > .Link {
  height: 2.2em;
  cursor: pointer;
  font-size: 1em;
  border: none;
  padding: 0;
  outline:none;
  width: 100%;
  text-align: left;
  background-color: transparent;
}

.MenuItemLayout > .Link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.MenuItemLayout > .Link > .Icon,
.MenuItemLayout > .Link > .Label {
  display: inline-block;
}

.MenuItemLayout > .Link > .Icon {
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: bolder;
  padding-right: 1em;
  width: 1.2em;
  text-align: center;
  color: darkblue;
}

.MenuItemLayout > .Link > .Label {
  color: #999;
}

.MenuItemLayout > .Link:hover > .Label {
  color: #777;
}

.MenuItemLayout[data-active="true"] > .Link {
  /* background-color: rgba(0, 0, 0, 0.05); */
  /* background-color: rgba(0, 0, 200, 0.05); */
  background-color: rgba(63, 78, 174, 0.05);
}

.MenuItemLayout[data-active="true"] > .Link > .Label {
  color: #333;
}

.MenuItemLayout > .Nested {
  overflow: hidden;
  max-height: 0;
  /*transition: max-height 100ms;*/
}

.MenuItemLayout[data-expanded="true"] > .Nested {
  max-height: 15em;
}

.MenuItemLayout > .Link > .Signal {
  position: absolute;
  right: 0px;
  top:0.5em;
  color: silver;
  transform: rotate(90deg);
  -webkit-transition: transform 2s; /* Safari prior 6.1 */
  transition: transform 0.25s
}

.MenuItemLayout[data-expanded="true"] > .Link > .Signal {
  transform: rotate(-90deg);
}
