.RadioControl {
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
}

.RadioControl > .Option {
  padding-left: 1.5em;
  cursor: pointer;
}

.RadioControl > .Option::before {
  position: absolute;
  left: 0em;
  content: " ";
  display: block;
  width: 1em;
  height: 1em;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px #035788;
  border-radius: 50%;
  box-sizing: border-box;
}

.RadioControl > .Option[data-selected="true"]::before {
  content: " ";
  border-color: white;
  border-width: 4px;
  background-color: #333;
}
