.RefundOrderForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Intro"
    "Name"
    "Email"
    "Order"
    "ItemsLabel"
    "Items"
    "Desc"
    "Reason"
    "Comment"

    "Attach"
    "Files"

    "Label"
    "LabelResponse"
    "Submit"
    "Shout";
}

.RefundOrderForm.NoOrderInfo {
  grid-template-areas:
    "Intro"
    "Name"
    "Email"
    "Order"
    "Desc"
    "Reason"
    "Comment"

    "Attach"
    "Files"

    "Label"
    "LabelResponse"
    "Submit"
    "Shout";
}

.RefundOrderForm > .Intro {
  grid-area: Intro;
}

.RefundOrderForm > .Name {
  grid-area: Name;
}

.RefundOrderForm > .Email {
  grid-area: Email;
}

.RefundOrderForm > .Order {
  grid-area: Order;
}

.RefundOrderForm > .Desc {
  grid-area: Desc;
}

.RefundOrderForm > .Reason {
  grid-area: Reason;
}

.RefundOrderForm > .Comment {
  grid-area: Comment;
}

.RefundOrderForm > .Attach {
  grid-area: Attach;
}

.RefundOrderForm > .Files {
  grid-area: Files;
}

.RefundOrderForm > .Label {
  grid-area: Label;
  margin-bottom: 0px;
}

.RefundOrderForm > .ItemsLabel {
  grid-area: ItemsLabel;
}

.RefundOrderForm > .Items {
  grid-area: Items;
}

.RefundOrderForm > .LabelResponse {
  grid-area: LabelResponse;
}

.RefundOrderForm > .Refund {
  display: none;
  grid-area: Refund;
  margin-bottom: 0px;
}

.RefundOrderForm > .RefundResponse {
  display: none;
  grid-area: RefundResponse;
}

.RefundOrderForm > .Submit {
  grid-area: Submit;
  margin-top: 1em;
}

.RefundOrderForm.NoOrderInfo > .ItemsLabel,
.RefundOrderForm.NoOrderInfo > .Items {
  display: none;
}

 @media (min-width: 45.5em) {
  .RefundOrderForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Intro   Intro"
      "Name    Name"
      "Email   Order"
      "ItemsLabel ItemsLabel"
      "Items   Items"
      "Desc    Desc"
      "Reason  ."
      "Comment Comment"
      "Comment Comment"

      "Attach  Attach"
      "Files   ."

      "Label    ."
      "LabelResponse   ."
      "Submit  Submit"
      "Shout   Shout";
    }

  .RefundOrderForm.NoOrderInfo {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Intro   Intro"
      "Name    Name"
      "Email   Order"
      "Desc    Desc"
      "Reason  ."
      "Comment Comment"
      "Comment Comment"

      "Attach  Attach"
      "Files   ."
      
      "Label   ."
      "LabelResponse   ."
      "Submit  Submit"
      "Shout   Shout";
    }

}
