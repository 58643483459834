.ECExperienceSurvey {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Name"
    "Email"
    "LikesLabel"
    "Likes"
    "DislikesLabel"
    "Dislikes"
    "WishlistLabel"
    "Wishlist"
    "ConfustionLabel"
    "Confustion"
    "DescriptionLabel"
    "Description"
    "FilesLabel"
    "Files"
    "Submit"
    "Shout";
}

.ECExperienceSurvey > .Name {
  grid-area: Name;
}

.ECExperienceSurvey > .Email {
  grid-area: Email;
}

.ECExperienceSurvey > .LikesLabel {
  grid-area: LikesLabel;
}

.ECExperienceSurvey > .Likes {
  grid-area: Likes;
}

.ECExperienceSurvey > .DislikesLabel {
  grid-area: DislikesLabel;
}

.ECExperienceSurvey > .Dislikes {
  grid-area: Dislikes;
}

.ECExperienceSurvey > .WishlistLabel {
  grid-area: WishlistLabel;
}

.ECExperienceSurvey > .Wishlist {
  grid-area: Wishlist;
}

.ECExperienceSurvey > .ConfustionLabel {
  grid-area: ConfustionLabel;
}

.ECExperienceSurvey > .Confustion {
  grid-area: Confustion;
}

.ECExperienceSurvey > .DescriptionLabel {
  grid-area: DescriptionLabel;
}

.ECExperienceSurvey > .Description {
  grid-area: Description;
}

.ECExperienceSurvey > .Submit {
  grid-area: Submit;
}

.ECExperienceSurvey > .Shout {
  grid-area: Shout;
}
