.FileControl {
  font-size: 1em;
  position: relative;
  display: inline-block;
  padding-bottom: 1.25em;
  border-bottom: 1px solid #eee;
}


.FileControl > .Add {
  /* font-size: 1.25em; */
  position: relative;
  display: inline-block;
  color: #035788;
  padding-left: 1em;
}

.FileControl > .Add > svg {
  transform: rotate(+135deg);
  margin-right: 1em;
}

.FileControl > .Add > input {
  position: absolute;
  opacity: 0;
}

.FileControl > .Add > input:hover {
  cursor: pointer;
}

.FileControl > .Add:hover {
  cursor: pointer;
}

.FileControl > .Items {
  box-sizing: border-box;
}

.FileControl > .Items > .Item {
  position: relative;
  margin-bottom: 0.5em;
  display: block;
  box-sizing: border-box;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  /* box-shadow: 0 3px 7px -3px rgba(0,0,0,0.20); */
  border-radius: 5px;
  background-color: #1d68940f;
}

.FileControl > .Items > .Item {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.FileControl > .Items > .Item > .Name {
  opacity: 0.5;
}

.FileControl > .Items > .Item > .Name > svg {
  /* color: blue; */
  padding-right: 1em;
}


.FileControl > .Items > .Item > .Action {
  color: #035788;
  width: 1em;
  height: 1em;
  text-align: center;
  border: 3px solid transparent;
  border-radius: 50%;
}

.FileControl > .Items > .Item > .Action:hover {
  color: red;
  cursor: pointer;
}

.FileControl > .Items > .Item > .Progress {
  position: absolute;
  bottom: 0px;
  left: 0em;
  height: 2px;
  background-color: #32a0fa;
  width: 0%;
  transition: width 0.5s;
}

.FileControl > .Items > .Item[data-status="loaded"] > .Action {
  color: transparent;
  border-color: rgba(0,0,0,0.35);
  border-top-color: rgba(0,0,0,0.10);
  transform: rotate(0deg);
  animation: File-process-spin infinite linear 0.65s;
  pointer-events: none;
}

.FileControl > .Items > .Item[data-status="processed"] > .Name {
  opacity: 1;
}

.FileControl > .Items > .Item[data-status="loaded"] > .Progress,
.FileControl > .Items > .Item[data-status="processed"] > .Progress {
  opacity: 0;
  transition: opacity 2s;
}

.FileControl > .Items > .Item[data-status="failed"],
.FileControl > .Items > .Item[data-status="aborted"]{
  color:red;
}

.FileControl > .Items > .Item[data-status="failed"] > .Progress,
.FileControl > .Items > .Item[data-status="aborted"] > .Progress {
  background-color:red;
}

.FileControl[data-error="true"] > .Add {
  color: red;
  animation: File-error-shake  infinite 2.5s;
}

@keyframes File-process-spin {
  100% {
    transform:rotate(360deg);
  }
}

@keyframes File-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
