.ReturnOrderForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Intro"
    "Name"
    "Email"
    "Order"
    "Desc"
    "Reason"
    "Comment"

    "Attach"
    "Files"

    "Submit"
    "Shout";
}

.ReturnOrderForm > .Intro {
  grid-area: Intro;
}

.ReturnOrderForm > .Name {
  grid-area: Name;
}

.ReturnOrderForm > .Email {
  grid-area: Email;
}

.ReturnOrderForm > .Order {
  grid-area: Order;
}

.ReturnOrderForm > .Desc {
  grid-area: Desc;
}

.ReturnOrderForm > .Reason {
  grid-area: Reason;
}

.ReturnOrderForm > .Comment {
  grid-area: Comment;
}

.ReturnOrderForm > .Attach {
  grid-area: Attach;
}

.ReturnOrderForm > .Files {
  grid-area: Files;
}

.ReturnOrderForm > .Submit {
  grid-area: Submit;
}

 @media (min-width: 45.5em) {
  .ReturnOrderForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Intro   Intro"
      "Name    Name"
      "Email   Order"
      "Desc    Desc"
      "Reason  ."
      "Comment Comment"
      "Comment Comment"

      "Attach  Attach"
      "Files   ."
      
      "Submit  Submit"
      "Shout   Shout";
    }
}
