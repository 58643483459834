.BottomMenu {
  position: relative;
  width: 100%;
  color: white;
  z-index: 10;
  box-sizing: border-box;
  text-align: center;
  background-color: black;
  min-height: 20em;
  text-align: center;
  z-index: 0;
}

.BottomMenu > .Content {
  text-align: center;
  display: inline-block;
  background-color: black;
  width: 100%;
  height: 20em;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 1em;
}

.BottomMenu > .Content > .Social {
  position: relative;
  opacity: 0.5;
  max-width: 5em;
  font-size: 2em;
  padding: 1em;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
}

.BottomMenu > .Content > .Foot {
  line-height: 1.5em;
  position: absolute;
  text-align: center;
  bottom: 3em;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
  width:100%;
}
