.PixBoxMenu {
  width: 100%;
  color: #333;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  align-items: center;
}

.PixBoxMenu > .Content {
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}
