.AddressControl {
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Company"
    "Address1"
    "Address2"
    "City"
    "Province"
    "Zip"
    "Country";
}

.AddressControl > .Company {
  grid-area: Company;
}

.AddressControl > .Address1 {
  grid-area: Address1;
}

.AddressControl > .Address2 {
  grid-area: Address2;
}

.AddressControl > .City {
  grid-area: City;
}

.AddressControl > .Province {
  grid-area: Province;
}

.AddressControl > .Zip {
  grid-area: Zip;
}

.AddressControl > .Country {
  grid-area: Country;
}
