.ECUnboxingSurvey {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Name"
    "Email"
    "ImpressionsLabel"
    "Impressions"
    "DissapointmentsLabel"
    "Dissapointments"
    "ConfusionLabel"
    "Confusion"
    "ImprovementsLabel"
    "Improvements"
    "InstructionsLabel"
    "Instructions"
    "FilesLabel"
    "Files"
    "Submit"
    "Shout";
}

.ECUnboxingSurvey > .Name {
  grid-area: Name;
}

.ECUnboxingSurvey > .Email {
  grid-area: Email;
}

.ECUnboxingSurvey > .ImpressionsLabel {
  grid-area: ImpressionsLabel;
}

.ECUnboxingSurvey > .Impressions {
  grid-area: Impressions;
}

.ECUnboxingSurvey > .DissapointmentsLabel {
  grid-area: DissapointmentsLabel;
}

.ECUnboxingSurvey > .Dissapointments {
  grid-area: Dissapointments;
}

.ECUnboxingSurvey > .ConfusionLabel {
  grid-area: ConfusionLabel;
}

.ECUnboxingSurvey > .Confusion {
  grid-area: Confusion;
}

.ECUnboxingSurvey > .ImprovementsLabel {
  grid-area: ImprovementsLabel;
}

.ECUnboxingSurvey > .Improvements {
  grid-area: Improvements;
}

.ECUnboxingSurvey > .InstructionsLabel {
  grid-area: InstructionsLabel;
}

.ECUnboxingSurvey > .Instructions {
  grid-area: Instructions;
}

.ECUnboxingSurvey > .Submit {
  grid-area: Submit;
}

.ECUnboxingSurvey > .Shout {
  grid-area: Shout;
}
