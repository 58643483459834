.Input {
  font-size: 1em;
  position: relative;
  height: 3.5em;
  min-width: 6em;
  text-align: left;
  display: inline-block;
}

/* clears the 'X' from Internet Explorer */
.Input input[type=search]::-ms-clear {  display: none; width : 0; height: 0; display: none; }
.Input input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; display: none; }

/* clears the 'X' from Chrome */
.Input input[type="search"]::-webkit-search-decoration,
.Input input[type="search"]::-webkit-search-cancel-button,
.Input input[type="search"]::-webkit-search-results-button,
.Input input[type="search"]::-webkit-search-results-decoration { display: none; }

.Input[data-focused="true"] {
  /* border-color: #999; */
  /* border-color: #0070c9; */
}

.Input > .Field {
  position: relative;
  height: 100%;
  width: calc(100% - 1em);
  background-color: transparent;
  border: none;
  border: 1px solid #EEE;
  border-radius: 5px;
  padding: 0px;
  padding-left: 1em;
  font-size: 1.25em;
  color: #555;
  transition: all 0.1s;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.Input[data-clearable="true"] > .Field {
  padding-right: 2em;
  width: calc(100% - 3em);
}


.Input > .Field:focus {
  outline-width: 0;
}

.Input > .Field::placeholder {
  color: silver;
}

.Input > .Label {
  position: absolute;
  top: 1.8em;
  left: 1em;
  text-align: left;
  pointer-events: none;
  font-size: 1.25em;
  transform: translateY(-0.8em);
  opacity: 0.5;
  line-height: 1em;
  transition: all 0.1s;
}

.Input > .Control {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5em;
  padding: 0.75em;
  color: silver;
}

.Input > .Control:hover {
  cursor: pointer;
}

.Input[data-filled="true"][data-clearable="true"] > .Control {
  display: block;
}

.Input[data-focused="true"] > .Label,
.Input[data-filled="true"] > .Label  {
  top: 1.2em;
  left: 1.6em;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}

.Input[data-focused="true"] > .Field,
.Input[data-filled="true"] > .Field {
  padding-top: 0.2em;
  height: calc(100% - 0.2em);
}

.Input[data-focused="true"] > .Field {
  border-color: #206893;
}

.Input[data-lock="true"] {
  pointer-events: none;
}
.Input[data-lock="true"] > .Field {
  background-color: rgba(0,0,0,0.05);
  color: gray;
}


.Input[data-error="true"] > .Field {
  border-color: red;
}

.Input[data-error="true"] > .Label {
  color: red;
}
.Input[data-focused="false"][data-error="true"] > .Label {
  animation: Input-error-shake  infinite 2.5s;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .Input {
    width: 100%;
  }
}

@keyframes Input-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
