.PlainButton {
  width: 1.2em;
  height: 1.2em;
  color: #333;
  font-weight: 400;
  text-align: center;
  line-height: 1.2em;
  display: inline-block;
  background-color: none;
  outline: none;
  border: none;
  padding: 0;
  white-space: nowrap;
}

.PlainButton:hover {
  cursor: pointer;
}

.PlainButton:active {
  color: black;
}

.PlainButton[data-layout="auto"] {
  width: auto;
  height: auto;
  color: inherit;
}

.PlainButton[data-theme="blue"] {
  color: #3f4eae;
}
