.Popup {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: white;
  width: calc(100% - 1.5em);
  max-width: 550px;
  max-height: 90%;
  overflow-y: scroll;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 25px 0px rgb(0, 0, 0, 0.5);
}

.Popup a {
  color: #035788;
}

.Popup .PopupFrame {
  position: relative;
  width: 100%;
  height: 100%;
  color: black;
  text-align: left;
  box-sizing: border-box;
  padding: 1.5em;
  border-radius: 0.5em;
}

.Popup .Close {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 1.25em;
  transition: color 0.25s ease;
}

.Popup .Close:hover {
  color: #035788;
  cursor: pointer;
}

.Popup .PopupFrame ul {
  padding-inline-start: 20px;
}

.Popup .PopupFrame .Controls {
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}
