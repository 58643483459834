.OrderForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Name"
    "Email"
    "Order"
    "Comment"
    "Submit"
    "Shout";
}

.OrderForm > .Name {
  grid-area: Name;
}

.OrderForm > .Email {
  grid-area: Email;
}

.OrderForm > .Order {
  grid-area: Order;
}

.OrderForm > .Comment {
  grid-area: Comment;
}

.OrderForm > .Submit {
  grid-area: Submit;
}

 @media (min-width: 45.5em) {
  .OrderForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Name    Name"
      "Email   Order"
      "Comment Comment"
      "Comment Comment"
      "Submit  Submit"
      "Shout   Shout";
    }
}
