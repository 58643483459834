.SugControl {
  position: relative;
  min-width: 6em;
}

.SugControl:hover {
  cursor: pointer;
}

.SugControl > .Input {
  width: 100%;
}

.SugControl > .Options {
  display: none;
  background-color: white;
  position: absolute;
  max-height: 15em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 6px -6px black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f5f5f5;
  color: #777;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.SugControl[data-focused="true"]  > .Options {
  width: 100%;
  display: block;
  z-index: 10;
}

.SugControl .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
}

.SugControl .Options > .Option:hover {
  background-color: #eee;
  color: #333;
}

.SugControl .Options > .Option > .Entry {
  text-align: left;
  box-sizing: border-box;
}

.SugControl .Options > .Option > .Entry > .Subnote {
  padding-top: 0.5em;
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.7;
}
