.Page {
  display: inline-block;
  text-align: left;
  padding: 1em;
  padding-top: 2.5em;
  padding-bottom: 2em;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  color: #333;
  min-height: 70vh;
}

.Page > .PageHeaderOutline,
.Page > .Section {
  margin-bottom: 1em;
}

.Page > .Header {
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.Page > .Header > div {
  display: inline-block;
}

.Page > .Header > .Title {
  color: darkblue;
  height: 2.2em;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 2.2em;
}

.Page > p {
  font-size: 1.5em;
}

@media (min-width: 45.5em) {
 .Page {
   padding-left: 2em;
   padding-right: 2em;
 }
}
