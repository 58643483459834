.ReplaceOrderForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-areas:
    "Intro"
    "Name"
    "Email"
    "Order"
    "Specify"
    "Product"
    "Desc"
    "Comment"

    "Attach"
    "Files" 

    "Where"
    "WhereResponse"
    "Address"
    "Ship"
    "Method"
    "Submit"
    "Shout";
}

.ReplaceOrderForm > .Intro {
  grid-area: Intro;
}

.ReplaceOrderForm > .Name {
  grid-area: Name;
}

.ReplaceOrderForm > .Email {
  grid-area: Email;
}

.ReplaceOrderForm > .Order {
  grid-area: Order;
}

.ReplaceOrderForm > .Specify {
  grid-area: Specify;
}

.ReplaceOrderForm > .Product {
  grid-area: Product;
}

.ReplaceOrderForm > .Desc {
  grid-area: Desc;
}

.ReplaceOrderForm > .Reason {
  grid-area: Reason;
}

.ReplaceOrderForm > .Comment {
  grid-area: Comment;
}

.ReplaceOrderForm > .Attach {
  grid-area: Attach;
}

.ReplaceOrderForm > .Files {
  grid-area: Files;
}

.ReplaceOrderForm > .Where {
  grid-area: Where;
}

.ReplaceOrderForm > .WhereResponse {
  grid-area: WhereResponse;
}

.ReplaceOrderForm > .Address {
  grid-area: Address;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s;
}

.ReplaceOrderForm > .Ship {
  grid-area: Ship;
}

.ReplaceOrderForm > .Method {
  grid-area: Method;
}

.ReplaceOrderForm > .Submit {
  grid-area: Submit;
  margin-top: 1em;
}

.ReplaceOrderForm[data-same-address="no"] > .Address {
  max-height: 1000px;
  overflow: visible;
}

 @media (min-width: 45.5em) {
  .ReplaceOrderForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Intro   Intro"
      "Name    Name"
      "Email   Order"
      "Specify Specify"
      "Product Product"
      "Desc    Desc"
      "Comment Comment"
      "Comment Comment"

      "Attach  Attach"
      "Files   ."

      "Where Where"
      "WhereResponse WhereResponse"
      "Address ."
      "Ship Ship"
      "Method  ."
      "Submit  Submit"
      "Shout   Shout";
    }
}
