.OrderStatusForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Email"
    "Order"
    "OrderNote"
    "Submit"
    "Shout";
}

.OrderStatusForm a {

}

.OrderStatusForm > .Name {
  grid-area: Name;
}

.OrderStatusForm > .Email {
  grid-area: Email;
}

.OrderStatusForm > .Order {
  grid-area: Order;
}

.OrderStatusForm > .OrderNote {
  grid-area: OrderNote;
  margin: 0; 
}

.OrderStatusForm > .Submit {
  grid-area: Submit;
}
