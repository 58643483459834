.FAQLayout {
  width: 100%;
  max-width: 100%;
  text-align: left;
  color: #333;
}

.FAQLayout > .Preview {
  flex-flow: wrap;
  justify-content: space-around;
  margin-bottom: 2em;
  box-sizing: border-box;
}

.FAQLayout > .Preview > .Category {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.FAQLayout > .Preview > .Category > .Title > .Control {
  display: none;
  transform: rotate(90deg);
  font-size: 0.75em;
  opacity: 0.5;
}

.FAQLayout > .Preview > .Category > .Title,
.FAQLayout > .Categories > .Category > .Title {
  font-weight: bold;
  font-size: 1.25em;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.FAQLayout > .Preview > .Category > .Title {
  position: relative;
  cursor: pointer;
}

.FAQLayout > .Preview > .Category > .Questions {
  max-height: 1000px;
  transition: max-height 0.5s, opacity 1.5s;
  transition-timing-function: ease-in;
  overflow: hidden;
}
.FAQLayout > .Preview > .Category > .Questions > a {
  color: #035788;
  display: block;
  line-height: 1.75em;
  margin-bottom: 0.5em;
  /* text-decoration: none; */
}

.FAQLayout > .Categories > .Category {
  margin-bottom: 2.5em;
}

.FAQLayout > .Categories > .Category > .Title {
  font-size: 2em;
}

.FAQLayout > .Categories > .Category > .Questions > .Question > .Anchor {
  position: relative;
}

.FAQLayout > .Categories > .Category > .Questions > .Question > .Title {
  font-weight: bold;
}

.FAQLayout > .Categories > .Category > .Questions > .Question > .Answer {
  box-sizing: border-box;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: rgba(0, 0, 0, 0.55);
}

.FAQLayout > .Categories > .Category > .Questions > .Question p,
.FAQLayout > .Categories > .Category > .Questions > .Question li {
  line-height: 1.5em;
}

.FAQLayout > .Categories .Answer img {
  height: 20em;
  width: 20em;
  object-fit: contain;
}

.FAQLayout > .Categories .Answer img.landscape {
  height: 10.8em;
  width: 19.2em;
}

.FAQLayout > .Categories .Answer img.portrait {
  height: 19.2em;
  width: 10.8em;
}

.FAQLayout > .Categories .Answer img.circle {
  object-fit: cover;
  width: 5em;
  height: 5em;
  border-radius: 2.5em;
}

.FAQLayout > .Categories .Answer img.wide {
  height: 4em;
  width: 20em;
}

.FAQLayout > .Categories .Answer a {
  color: #035788;
}

.FAQLayout > .Categories .Answer iframe {

}

.FAQLayout > .Categories .Answer iframe.landscape {
  height: 10.8em;
  width: 19.2em;
}

.FAQLayout > .Categories > .Category > .Questions > .Question {
  border-radius: 5px;
  transition-property: background-color, box-shadow;
  transition-duration: 0.5s;
  transition-delay: 1s;
}

.FAQLayout > .Categories > .Category > .Questions > .Question[data-highlight=true] {
  background-color: #ecf7ff;
  border-radius: 5px;
  box-shadow: -3px -5px 0px 5px #ecf7ff;
}


.FAQLayout > .Categories > .Category > .Questions > .Question > .Controls {
  box-sizing: border-box;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 1em;
  color: #035788;
}

.FAQLayout > .Categories > .Category > .Questions > .Question > .Controls > span {
  padding-right: 1em;
}

.FAQLayout > .Categories > .Category > .Questions > .Question > .Controls > span:hover {
  cursor: pointer;
}


@media (max-width: 60.5em) {

  .FAQLayout > .Preview > .Category {
    max-width: 100%;
    padding-left: 0em;
    padding-right: 0em;
  }

}

@media (max-width: 45.5em) {

  .FAQLayout > .Preview > .Category[data-collapsed="true"] > .Questions {
    max-height: 0px;
    /* opacity: 0; */
    transition: max-height 0.25s;
    transition-timing-function: ease-out;
  }

  .FAQLayout > .Preview > .Category > .Title > .Control {
    display: block;
    position: absolute;
    right: 0;
    top: 0.25em;
    font-weight: normal;
  }

  .FAQLayout > .Preview > .Category[data-collapsed="true"] > .Title > .Control {
    transform: rotate(-90deg);
  }
}
