.Readout {
  font-size: 1em;
  position: relative;
  height: 2.2em;
  min-width: 6em;
  /*border-top: 1px solid #EEE;*/
  /*border-radius: 0.5em;*/
  text-align: left;
}

.Readout > .Field {
  height: 100%;
  background-color: transparent;
  border: none;
  padding: 0px;
  padding-left: 1em;
  font-size: 1em;
  color: #555;
  line-height: 2.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* border-bottom: 1px solid #eee; */
}

.Readout > .Label {
  position: absolute;
  font-size: 0.75em;
  top: -0.8em;
  left: 1em;
  background-color: white;
  opacity: 1;
  padding-left: 0.5em;
  line-height: 1em;
}

.Readout[data-type="money"] > .Field {
  /* color: rgba(20,150,110,1); */
}


.Readout[data-clickable="true"]:hover > .Field {
  cursor: pointer;
  color: #3f4eae;
}
