.TopMenu {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  color: #333;
  z-index: 10;
  box-sizing: border-box;
  text-align: center;
  background: linear-gradient(135deg, black, #024f93);
}

.TopMenu > .Content {
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  height: 4.5em;
  padding: 1em;
  opacity: 0.9;
  display: grid;
  grid-template-columns: 1.75em 1fr 8em;
  grid-gap: 1em;
  align-items: center;
  grid-template-areas:
    "Logo     Title      Aside"
}

.TopMenu > .Content > .Logo {
  text-align: left;
  grid-area: Logo;
  max-height: 1.75em;
}

.TopMenu > .Content > .Logo:hover {
  cursor: pointer;
}

.TopMenu > .Content > .Title {
  text-align: left;
  grid-area: Title;
  font-size: 1.5em;
  color: white;
}

.TopMenu > .Content > .Title > i {
  color: orange;
  /* opacity: 0.90; */
}

.TopMenu > .Content > .Title:hover {
  cursor: pointer;
}

.TopMenu > .Content > .Aside {
  grid-area: Aside;
}

@media (min-width: 45.5em) {
 .TopMenu > .Content {
   padding-left: 2em;
   padding-right: 2em;
 }
}
