.LeftMenu {
  position: fixed;
  width: 16em;
  height:100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 5;
  display: inline-block;
}

.LeftMenu > .Content {
  position: relative;
  /* background-color: white; */
  background-color: #eee;
  padding: 1em;
  height: 100%;
  width: 100%;
  max-width: 20em;
  box-sizing: border-box;
  /* box-shadow: 0 0px 6px -5px black; */
  border-right: 1px solid #ddd;
}

.LeftMenu > .Content > .MenuTop {
  height: 2.2em;
  /* border-bottom: 1px solid silver; */
  margin-bottom: 1em;
}

.LeftMenu > .Content > .Controls {
  position: absolute;
  top:0;
  left: -1em;
  background-color: transparent;
  width: calc(100% + 3.5em);
  text-align: right;
}

.LeftMenu > .Content > .Controls > .PlainButton {
  right: -2.5em;
  height: 2em;
  font-size: 2em;
  line-height: 2em;
  color: white;
}

/* Small screens */
@media (max-width: 60.5em) {
  .LeftMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
  }

  .LeftMenu > .Content {
    position: relative;
    background-color: white;
    padding: 1em;
    height: 100%;
    width: calc(100% - 4em);
    max-width: 20em;
    box-sizing: border-box;
  }

  .LeftMenu[data-expanded="false"] {
    max-width: 0px;
    overflow: hidden;
  }
}
