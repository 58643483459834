.TextArea {
  font-size: 1em;
  position: relative;
  min-height: 7em;
  min-width: 6em;
  text-align: left;
  display: inline-block;
}

.TextArea[data-focused="true"] {
  border-color: #0070c9;
}

.TextArea > .Field {
  position: relative;
  min-height: 7em;
  width: calc(100% - 1em);
  background-color: transparent;
  border: none;
  border: 1px solid #EEE;
  border-radius: 5px;
  padding: 0px;
  padding-left: 1em;
  padding-bottom: 0.5em;
  font-size: 1.25em;
  color: #555;
  transition: all 0.1s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.TextArea > .Field:focus {
  outline-width: 0;
}

.TextArea > .Field::placeholder {
  color: silver;
}

.TextArea > .Label {
  position: absolute;
  top: 1.8em;
  left: 1em;
  text-align: left;
  pointer-events: none;
  font-size: 1.25em;
  transform: translateY(-0.8em);
  opacity: 0.5;
  line-height: 1em;
  transition: all 0.1s;
}

.TextArea[data-focused="true"] > .Label,
.TextArea[data-filled="true"] > .Label  {
  top: 1.2em;
  left: 1.6em;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}

.TextArea[data-focused="true"] > .Field,
.TextArea[data-filled="true"] > .Field {
  padding-top: 1.1em;
  padding-bottom: 0em;
  height: calc(100% - 1.6em);
}

.TextArea[data-focused="true"] > .Field {
  border-color: #206893;
}

.TextArea[data-lock="true"] {
  pointer-events: none;
}
.TextArea[data-lock="true"] > .Field {
  background-color: rgba(0,0,0,0.05);
  color: gray;
}

.TextArea[data-error="true"] > .Field {
  border-color: red;
}

.TextArea[data-error="true"] > .Label {
  color: red;
}
.TextArea[data-focused="false"][data-error="true"] > .Label {
  animation: TextArea-error-shake  infinite 2.5s;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .TextArea {
    width: 100%;
  }
}

@keyframes TextArea-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
