.PixBoxLayout {
  box-sizing: border-box;
  /* background-color: white; */
  padding: 1em;
  max-width: 16.5em;
  color: #035788;
  /* display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.25em;
  align-items: center;
  grid-template-areas:
    "Icon"
    "Title"
    "Description";
    -webkit-transition: color 0.35s;
    transition: color 0.35s; */
}

.PixBoxLayout:hover {
  /* color: #0070c9; */
  cursor: pointer;
}

.PixBoxLayout > .Icon {
  font-size: 5em;
  grid-area: Icon;
  cursor: inherit;
  /* color: #333; */
}

.PixBoxLayout > .Icon > img {
  height: 2em;
}

.PixBoxLayout > .Title {
  grid-area: Title;
  font-size: 1.5em;
  /* padding-bottom: 0.25em; */
  /* font-weight: bold; */
  cursor: inherit;
}

.PixBoxLayout > .Description {
  grid-area: Description;
  font-size: 1em;
  cursor: inherit;
  opacity: 0.75;
}
